@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Nunito', sans-serif;
  background-color: rgb(156, 139, 238);
}


.App {
  text-align: center;
}

#editor {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}


#texto-h2 {
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  color: rgb(26, 29, 32);
}

#preview {
  background-color:white;
  width: 60%;
  margin-top: 3rem;
  margin-left:auto;
  margin-right:auto;
  margin-bottom: 6rem;
  align-items:  center;
}


